<template>
  <v-container id="services" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0 main">
      <v-col cols="12" class="ma-auto pa-0">
        <v-row>
          <v-col cols="12" md="10" lg="8" class="ma-auto my-12 py-12" data-aos="slide-right">
            <h1 class="text-h2 text--secondary">{{$t("itservices")}}</h1>
            <div class="text-h4 text--secondary font-weight-light my-8">{{$t("servicesdesc")}}</div>
            <v-btn class="custom-accent--text" @click="scrollToId('contact')" :title="$t('requestaquote')" dark depressed rounded outlined>{{$t("requestaquote")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row class="custom-accent white--text">
          <v-col cols="12" md="10" lg="8" class="ma-auto py-12 text-center">
            <v-row class="pa-0 ma-0" height="auto">
              <v-col cols="12" class="text-center mb-5" data-aos="slide-bottom">
                <span class="text-h3">{{$t("ouritservices")}}</span>
              </v-col>
              <v-col cols="12" class="mb-4" data-aos="zoom-in">
                <v-divider class="mx-2 white"></v-divider>
              </v-col>
              <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('services.create')"
                cols="12">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block height="60" dark x-large v-bind="attrs" v-on="on" @click="OpenCreateService()" text outlined :title="$t('create')"><v-icon large>mdi-plus-box</v-icon></v-btn>
                  </template>
                  <span>{{$t('create')}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-if="service_loading"> 
              <v-col cols="12" v-for="i in 6" :key="i" class="ma-auto" sm="6" md="4" data-aos="fade-in">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0" v-else> 
              <v-col cols="12" v-for="item in services" :key="item.id" sm="6" md="4">
                <v-card height="100%" class="values-cards transparent elevation-0" data-aos="zoom-in">
                  <v-row class="pa-0 ma-0" style="position:absolute;right:0px;top:0px;" v-if="user && isLoggedIn">
                    <v-col class="pa-0 ma-0" cols="12">
                      <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('services.update')">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon dark v-bind="attrs" v-on="on" @click.stop="OpenUpdateService(item)" :title="$t('update')">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('update')}}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col class="pa-0 ma-0" cols="12">
                      <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('services.delete')">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon dark v-bind="attrs" v-on="on" @click.stop="OpenDeleteService(item)" :title="$t('delete')">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('delete')}}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-card-title>
                    <v-avatar
                      class="ma-auto"
                      style="border: 1px solid white !important;"
                      size="88">
                      <v-icon class="white--text" x-large>
                        {{item.icon}}
                      </v-icon>
                    </v-avatar>
                  </v-card-title>
                  <v-card-title>
                    <span class="ma-auto v-html-line-clamp-1 white--text">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</span>
                  </v-card-title>
                  <v-card-text class="white--text font-weight-light">
                    <expandable-text-line class="overflow-hidden">
                      <span v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span>
                    </expandable-text-line>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-center text-md-left">
          <v-col cols="12" md="12" lg="10" class="ma-auto my-6 py-12">              
            <div class="text-h4 text-uppercase text-center text--secondary" data-aos="fade">{{$t("seewhatsnew")}}</div>
            <v-row class="ma-0 pa-0" v-if="blog_loading"> 
              <v-col class="ma-auto pa-12" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-block d-md-block d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-none d-md-block d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
              <v-col class="ma-auto pa-12 d-none d-sm-none d-md-none d-lg-block" cols="12" sm="6" md="4" lg="3" data-aos="fade-down">
                <v-skeleton-loader
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader class="my-2"
                  type="heading"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="sentences,button"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <div class="ma-0 pa-0" v-else>
              <div class="custom-off-white" data-aos="flip-up">
                <swiper class="swiper pa-12" :options="swiperOption">
                  <swiper-slide v-for="(item,i) in blogs" :key="i">
                    <v-card class="mx-auto elevation-0 rounded-xl" outlined>
                      <v-img v-if="item.image" :src="storage_url+path+item.image" :alt="item.alt" height="175px">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="custom-secondary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-img v-else :src="storage_url+no_image_path+'noimage.png'" :alt="$t('noimagealt')" height="175px">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="custom-secondary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-card-title>
                        <span class="v-html-line-clamp-1 mb-2">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</span>
                      </v-card-title>
                      <v-card-subtitle class="blog-values-cards">
                        <span class="v-html-line-clamp-2 custom-secondary--text" v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span>
                      </v-card-subtitle>
                      <v-card-actions>
                        <v-btn color="custom-accent" :title="$t('viewmore')" text @click="$navigateToPath('/blog/news-events/ /'+item.id)">
                          {{$t("viewmore")}}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </swiper-slide>
                  <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev custom-accent--text" slot="button-prev"></div>
                  <div class="swiper-button-next custom-accent--text" slot="button-next"></div>
                </swiper>
              </div>
            </div>
            <div class="px-12">
              <v-btn class="custom-accent" :title="$t('visitourblog')" @click="$navigateToPath('/blog/news-events')" dark depressed rounded>{{$t("visitourblog")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row data-aos="fade-down" class="custom-accent white--text" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h4 text-uppercase">{{$t("servicesknowmore")}}</div>
            <v-btn class="my-8" @click="$navigateToPath('/contact')" :title="$t('leaveusamessage')" dark depressed rounded>{{$t("leaveusamessage")}}<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
            <contact-us-form data-aos="fade-right" :isDark="true" :type="'services'"></contact-us-form>
          </v-col>
        </v-row>

        <!-- create dialog -->
        <v-dialog v-model="servicecreateoverlay" width="800px">
          <v-card v-if="servicecreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <create-service @isValid="validate" :NewItem="activeservice"></create-service>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="servicecreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearService()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="service_loading" :disabled="!servicecreateformvalid" @click="CreateService()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="serviceupdateoverlay" width="800px">
          <v-card v-if="serviceupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <update-service @isValid="validate" :UpdatedItem="activeservice"></update-service>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="serviceupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="service_loading" depressed :disabled="!serviceupdateformvalid" @click="UpdateService()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="servicedeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="servicedeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="service_loading" depressed @click="DeleteService()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../../components/Contact/ContactUsForm.vue';
import ExpandableTextLine from 'vue-expandable-text-line';
import createservice from '../../components/Service/CreateService.vue';
import updateservice from '../../components/Service/UpdateService.vue';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Services",

  data() {
    return {
      servicedetailsoverlay: false,
      servicecreateoverlay: false,
      serviceupdateoverlay: false,
      servicedeleteoverlay: false,
      servicecreateformvalid: false,
      serviceupdateformvalid: false,
      activeservice: {},

      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 60,
        notNextTick: true,
        initialSlide: 0,
        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        speed: 800,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable:true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1524: {
            slidesPerView: 5,
            spaceBetween: 50
          },
          1224: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          904: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "blogs/newsevents/images/",
      no_image_path: "default_images/",
    };
  },
  components: {
    'contact-us-form':contactusform,
    'expandable-text-line':ExpandableTextLine,
    'create-service':createservice,
    'update-service':updateservice,
  },
  created() {
  },
  computed: {
    ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('service', {services:'getServices'}),
    ...mapState("service", {service_loading: "loading"}),
    ...mapGetters('blog', {blogs:'getBlogs'}),
    ...mapState("blog", {blog_loading: "loading"}),
  },
  watch: {
  },
  methods: {
    scrollToId(id){
      let element = document.getElementById(id);
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.servicecreateformvalid=isValid;
            break;

        case 'update':
            this.serviceupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    OpenCreateService() {
      this.servicecreateoverlay = true;
      this.activeservice = {};
    },
    OpenUpdateService(item) {
      this.serviceupdateoverlay = true;
      this.activeservice = {...item};
    },
    OpenDeleteService(item) {
      this.servicedeleteoverlay = true;
      this.activeservice = {...item};
    },
    CreateService() {
      if(this.servicecreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('services.create')){
        this.addService(this.activeservice).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
            this.servicecreateoverlay = false;
            this.activeservice = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateService() {
      if(this.serviceupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('services.update')){
        this.updateService(this.activeservice).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.serviceupdateoverlay = false;               
            this.activeservice = {};
            this.UpdateServices(response.data);
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateServices(payload) {
      const edited_item_index=this.services.findIndex(
          (item) => item.id === payload.id
        );
      if (edited_item_index !== -1)
          this.services[edited_item_index] = payload;
    },
    DeleteService() {
      if(this.user && this.user.permission.map(i=>i.name).includes('services.delete')){
        this.deleteService(this.activeservice).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.servicedeleteoverlay = false;
            this.activeservice = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearService() {
      this.activeservice = {};
    },

    ...mapActions("service", ["addService","updateService","deleteService"]),
  },
};
</script>
<style scoped>
@import url('../../assets/styles/services.css');
</style>